$label-width:4.2rem;

label {
  display:block;
  margin-bottom:1.5rem;
  padding:0.25rem 0;

  @include screen-medium {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  span {
    display:block; 
    margin-bottom:0.25rem;
    flex-shrink:0;
    flex-grow:0;

    @include screen-medium {
      display:inline-block;
      height:2.5rem;
      line-height:2.5rem;
      vertical-align:top;
      text-align:right;
      margin-right:1rem;
      min-width:$label-width;
      margin-bottom:0;
    }
  }
}

input[type=email],
input[type=text],
textarea {
  height:2.5rem;
  border-radius:0.25rem;
  border:0;
  padding-bottom:0.5rem;
  font-size:1rem;
  font-family:$body-font-family;
  width: 100%;
  padding-left:0.5rem;
  padding-top:0.5rem;
  flex-shrink:1;
  flex-grow:1;
  background:mix($background-color, black, 60%);
  color:$text-color;  

  &::placeholder {color:rgba(white, 0.6);}
  

  @include screen-medium {
      max-width:38rem;
  }
  &:focus {
    @extend %focus;
    background:mix($background-color, black, 40%);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
  }

  &:hover {
    background:mix($background-color, black, 40%);
  }
}

textarea {
  height:6rem;
}

button[type=submit] {
  font-size:1rem;
  font-family:$body-font-family;
  font-weight:bold;
  padding:0.75rem 1.5rem;
  border-radius:0.25rem;
  border: 2px solid mix($accent-color, $background-color, 60%);
  background-color:rgba($accent-color,0.15);
  color: $text-color;

  @include focus-after(0.25rem, 0.25rem) {}

  &, &:after {
    transition: transform 0.1s;
  }

  &:hover {
    background-color:rgba($accent-color,0.35);
    border-color: mix($accent-color, $background-color, 90%);
  }
  &:active {transform:scale(calc(0.95))}
  &:active:after {transform:scale(1.0526);}

  @include screen-medium {
    margin-left:$label-width + 1rem;
  }
}