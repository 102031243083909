@function emify($px, $font-size:16px) {
  @return calc($px / $font-size * 1em);
}

@mixin screen-min-width($width) {
  @media screen and (min-width: emify($width)) {
    @content;
  }
}

@mixin screen-medium {
  @include screen-min-width(768px) {
    @content;
  }
}

@mixin screen-large {
  @include screen-min-width(996px) {
    @content;
  }
}

@mixin screen-xlarge {
  @include screen-min-width(1200px) {
    @content;
  }
}

@mixin screen-xxlarge {
  @include screen-min-width(1400px) {
    @content;
  }
}