main.home {
  
}

.apps {
  h3 {
    border-bottom:0;
    margin-bottom: 0.5rem;
  }

  .app {
    padding-bottom: 2rem;
  }
}

.app-links {
  a:not(:last-child) {
    margin-right:2rem;
  }

  svg {
    vertical-align: bottom;
    margin-right:0.5rem;
  }
  path {
    fill:currentColor;
  }
}