* {box-sizing:border-box;}
html, body {margin:0; padding:0;}

%focus {
  outline: 4px solid transparent;
  outline-offset:0;
  box-shadow: 0 0 0 2px $background-color, 0 0 0 6px $focus-color;
  animation: focus-pulse ease-in-out 0.25s;
}

@mixin focus-after($offset: 2px, $radius:0px) {
  &:focus {
    outline: 0;
  }

  position:relative;

  &:focus:after {
    content: '';
    display:block;
    position:absolute;
    top: #{-$offset};
    left: #{-$offset};
    width:calc(100% + #{2 * $offset});
    height:calc(100% + #{2 * $offset});
    border-radius: $radius;
    z-index:1;
    @extend %focus;
    @content;
  }
}

@keyframes focus-pulse {
  0% {box-shadow: 0 0 0 4px $background-color, 0 0 0 12px rgba($focus-color,0.6);}
  100% {box-shadow: 0 0 0 2px $background-color, 0 0 0 6px $focus-color;}
}