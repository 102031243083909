@import 'theme.scss';
@import 'base.scss';
@import 'breakpoints.scss';
@import 'form.scss';
@import 'page-index.scss';
@import url(apps.css);


body {
  background:$background-color;
  color: $text-color;
  font-family: $body-font-family;
  min-height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  font-size:16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
  border-bottom: 0.125rem solid mix($text-color, $background-color, 50%);
  padding-bottom:0.5rem;
}
h1 {font-size:2.5rem;}
h2{font-size:2rem; margin-top:4rem;}
h3 {font-size:1.8rem; margin-top:3rem;}

main {
  box-sizing: border-box;
  display:block;
  width:100%;
  max-width:38rem;
  padding:2rem 1rem;

  img {max-width:100%;}
}

.hero {
  margin-bottom: 4rem;
  @include screen-medium {
    margin-bottom:25vh;
    margin-top: 25vh;
  }

  h1 {
    text-align:center;
    font-size:3.2rem;
    border-bottom: 0.25rem solid $accent-color;
    padding-bottom:0.5rem;
    
    img {vertical-align:bottom}
  }

  p {
    @include screen-medium {text-align:justify;}
    line-height: 2rem;
    font-size:1.5rem;
  }
}

a:focus {@extend %focus;}
a::-moz-focus-inner {border:0;}

a,
a:visited,
a:active, {
  color:$link-color;
}

footer {
  padding:1rem;
  font-size:0.8rem;
  color:#c0c0c0;
  justify-self: flex-end;

  p {
    text-align:center;
    margin:0.125rem 0
  }

  a {margin: 0 0.5rem}

}

p, li {
  line-height:1.4rem;
}
.flex-spacer {
  flex-grow:9999;
}